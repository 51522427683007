@media screen and (min-width: 720px) {
  .security{
     
    &-content{
      max-width: 1280px;
      margin: 0 auto;
      padding: 40px 0 80px;
    }

    &-list{
      display: flex;

      &-item{
        &-image{
          display: block;
          margin: 0 auto;
          width: 240px;
          height: 240px;
          background: #eee center / 50% auto no-repeat;

          &-1{
            background-image: url('./images/whitelist.png');
          }

          &-2{
            background-image: url('./images/stoploss.png');
          }

          &-3{
            background-image: url('./images/safe.png');
          }
        }

        >h3{
          text-align: center;
          margin: 30px 0 20px;
          font-size: 24px;
        }
        >p{
          padding: 0 20px;
          line-height: 1.5;
        }
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .security{
     
    &-content{
      margin: 0 auto;
      padding: 20px 0 20px;
    }

    &-list{
      display: block;

      &-item{
        margin-bottom: 20px;
        &-image{
          display: block;
          margin: 0 auto;
          width: 160px;
          height: 160px;
          background: #eee center / 50% auto no-repeat;

          &-1{
            background-image: url('./images/whitelist.png');
          }

          &-2{
            background-image: url('./images/stoploss.png');
          }

          &-3{
            background-image: url('./images/safe.png');
          }
        }

        >h3{
          text-align: center;
          margin: 16px 0 8px;
          font-size: 24px;
        }
        >p{
          font-size: 14px;
          text-align: center;
          padding: 0 20px;
          line-height: 1.5;
        }
      }
    }
  }
}

