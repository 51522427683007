$bull-color: #e6006e;
$bear-color: #e6006e;
$gray-color: #898989;

%linear-bg{
  background: linear-gradient(90deg, #0FFF87 0%, #3BC3F3 50%, #D93389 100%);
}

@mixin linear-text($gradient: linear-gradient(90deg, #0FFF87 0%, #3BC3F3 50%, #D93389 100%)) {
  display: inline-block;
  background: $gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin linear-text-normal {
  @include linear-text(linear-gradient(90deg, #50d9f6, #bd70d5));
}

@mixin main-btn{
  display: block;
  background: rgba(#000, .2);
  font-weight: bold;
  // border-radius: 10px;
  letter-spacing: 4px;
  font-size: 28px;
  padding: 20px 0;
  width: 300px;
  color: #50d9f6;
  text-align: center;
  border: 2px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(90deg, #50d9f6, #bd70d5);
  transition: all .2s ease-in-out;

  &:hover{
    background: rgba(#000, .4);
  }
}

.content{
  max-width: 1280px;
  margin: 0 auto;
}
// @mixin content-box {
//   border-radius: 20px;
//   padding: 30px 30px;
//   background: rgba(#000, .4);
// }