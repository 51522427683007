@media screen and (min-width: 720px) {
  .nav{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
     
    border-bottom: 2px solid transparent;
    &.fixed{
      background: #fff;
      border-bottom: 2px solid #000;
    }
    &-inner{
      display: flex;
      align-items: center;
      max-width: 1280px;
      margin: 0 auto;
      height: 100%;
      position: relative;
    }
  
    &-logo{
      font-size: 24px;
      display: flex;
      align-items: center;
      i{
        width: 56px;
        height: 56px;
        background: url('./images/logo.svg') center / contain no-repeat;
      }
    }
  
    &-list{
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      height: 100%;
      align-items: center;
      gap: 40px;
      >li{
        font-size: 18px;
        position: relative;
        padding: 10px 0;
        a{
          &:after{
            transform: scale(0);
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            bottom: 0;
            border-bottom: 2px solid #000;
            transition: .3s ease-in-out;
          }
        }
        &:hover{
          a{
            &::after{
              transform: scale(1);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .nav{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    padding: 0 20px;
     
    border-bottom: 2px solid transparent;
    &.fixed{
      background: #fff;
      border-bottom: 1px solid #000;
    }
    &-inner{
      display: flex;
      align-items: center;
      margin: 0 auto;
      height: 100%;
      position: relative;
    }
  
    &-logo{
      font-size: 18px;
      display: flex;
      align-items: center;
      i{
        width: 32px;
        height: 32px;
        background: url('./images/logo.svg') center / contain no-repeat;
      }
    }
  
    &-list{
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      height: 100%;
      align-items: center;
      gap: 20px;
      >li{
        font-size: 14px;
        position: relative;
        padding: 10px 0;
        a{
          &:after{
            transform: scale(0);
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            bottom: 0;
            border-bottom: 2px solid #000;
            transition: .3s ease-in-out;
          }
        }
        &:hover{
          a{
            &::after{
              transform: scale(1);
            }
          }
        }
      }
    }
  }
}

