@media screen and (min-width: 720px) {
  .lever{
     
    padding: 40px 0 120px;
    &-content{
      max-width: 1280px;
      margin: 0 auto;
      padding-top: 40px;
      >h2{
        font-size: 28px;
        line-height: 50px;
      }
      >p{
        font-size: 16px;
        line-height: 1.5;
      }
    }
  
    &-image{
      margin: 0 auto;
      width: 800px;
      height: 800px;
      border: 2px solid #000;
    }
    
  }
}

.leverage-image{
  &-1{
    background-image: url('./images/safe.png');
  }
  &-2{
    background-image: url('./images/protection.png');
  }
}

@media screen and (max-width: 720px) {
  .lever{
     
    padding: 20px 0 40px;
    &-content{
      margin: 0 auto;
      padding-top: 20px;
      >h2{
        font-size: 18px;
        line-height: 50px;
      }
      >p{
        font-size: 14px;
        line-height: 1.5;
      }
    }
  }
}

