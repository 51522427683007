@media screen and (min-width: 720px) {
  .strategy{
     

    &-content{
      padding-top: 40px;
      max-width: 1280px;
      margin: 0 auto;
    }

    &-list{
      display: flex;
      flex-wrap: wrap;
      &-item{
        width: 50%;
        position: relative;
        height: 240px;
        padding-left: 220px;
        &-image{
          position: absolute;
          left: 0;
          top: 0;
          width: 200px;
          height: 200px;
          border: 1px solid #000;
          background: center / contain no-repeat;
          &-1{
            background-image: url('./images/farm.png');
          }
          &-2{
            background-image: url('./images/lend.png');
          }
        }
        >h3{
          font-size: 20px;
          margin-bottom: 15px;
        }
        >h4{
          font-size: 16px;
          color: #666;
          margin-bottom: 15px;
        }
        >p{
          font-size: 14px;
          line-height: 1.5;
          color: #999;
        }
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .strategy{
     

    &-content{
      padding-top: 40px;
      margin: 0 auto;
    }

    &-list{
      display: flex;
      flex-wrap: wrap;
      padding: 0 20px;
      &-item{
        position: relative;
        min-height: 180px;
        padding-left: 180px;
        &-image{
          position: absolute;
          left: 0;
          top: 0;
          width: 160px;
          height: 160px;
          border: 1px solid #000;
          background: center / contain no-repeat;
          &-1{
            background-image: url('./images/farm.png');
          }
          &-2{
            background-image: url('./images/lend.png');
          }
        }
        >h3{
          font-size: 20px;
          margin-bottom: 15px;
        }
        >h4{
          font-size: 16px;
          color: #666;
          margin-bottom: 15px;
        }
        >p{
          font-size: 14px;
          line-height: 1.5;
          color: #999;
        }
      }
    }
  }
}
