@media screen and (min-width: 720px) {
  .kv{
    position: relative;
    height: 100vh;
    background: linear-gradient(180deg, #E2EEEC, transparent);
     
  
    &-inner{
      max-width: 1280px;
      margin: 0 auto;
      position: relative;
    }
  
    &-image{
      position: absolute;
      right: 100px;
      top: 50%;
      transform: translateY(-50%);
      height: 100vh;
      width: 40%;
      background: url('./images/kv.png') center / contain no-repeat;
      // box-shadow: 5px 5px 5px#E2EEEC;
    }
  
    &-title{
      display: grid;
      align-content: center;
      height: 100vh;
      padding-left: 100px;
      gap: 30px;
      h1{
        font-size: 100px;
      }
      p{
        width: 500px;
        font-size: 24px;
        line-height: 1.2;
        color: #666;
      }
      a{
        width: 240px;
        height: 70px;
        border-radius: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 26px;
        background: #21534B;
        transition: .2s ease-in-out;
  
        &:hover{
          background: darken(#21534B, 5%);
        }
      }
    }
  
    &-mouse{
      position: absolute;
      right: 20px;
      bottom: 40px;
      >p{
        transform: rotate(-90deg) translateX(-50px) translateY(-58px);
      }
      >i{
        display: block;
        width: 45px;
        height: 80px;
        background: url('./images/mouse.png') center / contain no-repeat;
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .kv{
    position: relative;
    height: 100vh;
    background: linear-gradient(180deg, #E2EEEC, transparent);
     
  
    &-inner{
      margin: 0 auto;
      position: relative;
    }
  
    &-image{
      // position: absolute;
      // right: 100px;
      // top: 50%;
      // transform: translateY(-50%);
      // height: 100vh;
      // width: 40%;
      // background: url('./images/data.png') center / contain no-repeat;
      // box-shadow: 5px 5px 5px#E2EEEC;
    }
  
    &-title{
      display: grid;
      align-content: center;
      text-align: center;
      height: 100vh;
      gap: 16px;
      h1{
        font-size: 60px;
      }
      p{
        font-size: 16px;
        line-height: 1.2;
        color: #666;
      }
      a{
        margin: 0 auto;
        width: 160px;
        height: 50px;
        border-radius: (50px/2);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 18px;
        background: #21534B;
        transition: .2s ease-in-out;
  
        &:hover{
          background: darken(#21534B, 5%);
        }
      }
    }
  
    &-mouse{
      display: none;
    }
  }
}

