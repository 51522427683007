@media screen and (min-width: 720px) {
  .feature{
    padding-bottom: 120px;
     
  }
  .feature-list{
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    &-item{
      width: 580px;
      height: 580px;
      padding-top: 20px;
      //  background: rgba(#eee, 1);
      border: 2px solid #000;
      position: relative;
      &-tag{
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 28px;
      }

      &-image{
        display: block;
        margin: 0 auto;
        width: 400px;
        height: 400px;
        background: #eee center / 90% auto no-repeat;
      }

      >h3{
        text-align: center;
        font-size: 24px;
        padding: 20px 0;
      }
      >p{
        padding: 0 10px;
        line-height: 1.5;
        text-align: center;
      }


      &:first-child{
        .feature-list-item-image{
          background-image: url('./images/1.png');
        }
      }
      &:nth-child(2){
        .feature-list-item-image{
          background-image: url('./images/2.png');
        }
      }
      &:nth-child(3){
        .feature-list-item-image{
          background-image: url('./images/3.png');
        }
      }
      &:nth-child(4){
        .feature-list-item-image{
          background-image: url('./images/4.png');
        }
      }
    }
  } 
}



@media screen and (max-width: 720px) {
  .feature{
    padding-bottom: 20px;
     
  }
  .feature-list{
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    &-item{
      padding: 20px 0 20px;
      width: calc(100% - 40px);
      //  background: rgba(#eee, 1);
      border: 2px solid #000;
      position: relative;
      &-tag{
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 20px;
      }

      &-image{
        display: block;
        margin: 0 auto;
        width: 70%;
        padding-top: 60%;
        background: #eee center / 90% auto no-repeat;
      }

      >h3{
        text-align: center;
        font-size: 24px;
        padding: 16px 0 10px;
      }
      >p{
        font-size: 14px;
        padding: 0 10px;
        line-height: 1.5;
        text-align: center;
      }

      &:first-child{
        .feature-list-item-image{
          background-image: url('./images/1.png');
        }
      }
      &:nth-child(2){
        .feature-list-item-image{
          background-image: url('./images/2.png');
        }
      }
      &:nth-child(3){
        .feature-list-item-image{
          background-image: url('./images/3.png');
        }
      }
      &:nth-child(4){
        .feature-list-item-image{
          background-image: url('./images/4.png');
        }
      }
    }
  } 
}

