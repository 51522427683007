@media screen and (min-width: 720px) {
  .title{
    font-size: 48px;
    text-align: center;
    h2{
      padding: 10px 0;
      color: #21534B;
      display: inline-block;
      margin: 0 auto;
      position: relative;
  
      &::before,
      &::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 4px;
        border-radius: 2px;
        background: #21534B;
      }
  
      &::before{
        bottom: 0;
        transform: translateX(-15%);
      }
  
      &:after{
        top: 0;
        transform: translateX(-85%);
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .title{
    font-size: 24px;
    text-align: center;
    h2{
      padding: 10px 0;
      color: #21534B;
      display: inline-block;
      margin: 0 auto;
      position: relative;
  
      &::before,
      &::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        background: #21534B;
      }
  
      &::before{
        bottom: 0;
        transform: translateX(-15%);
      }
  
      &:after{
        top: 0;
        transform: translateX(-85%);
      }
    }
  }
}

